import React from "react";
import styled from "styled-components";
import Contact from "../components/Contact";

const UTSHeroStyle = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #FF6C02 0%, #FF9E00 100%);
    padding: 50px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .UTSHero-text {
        color: white;
        .title {
            font-size: 40px;
            font-weight: bold;
            span {
                color: #FFE600;
                font-weight: bold;
                margin-left: 10px;
            }
        }
        h3 {
            margin-bottom: 10px;
        }
    }
    .UTSHero-imgs {
        width: 100%;
        max-width: 1280px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: -20px;
        padding-right: 40px;
        .center {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 500px;
            z-index: 1;
        }
        .left-img {
            width: 660px;
        }
        .right-img {
            width: 393px;
        }
    }
    .UTSHero-imgs img {
        width: 100%;
    }
    @media (max-width: 976px) {
        .UTSHero-imgs {
            .center {
                width: 400px;
            }
        }
    }
    @media (max-width: 768px) {
        padding: 30px 0 0;
        .UTSHero-imgs {
            height: 360px;
            .left-img, .right-img, .center {
                display: none;
            }
            background: url("https://mtimg.onemiao.cn/web-images/uts/uts_hero_s.png") center bottom no-repeat;
            background-size: 80%;
        }
    }
    @media (max-width: 640px) {
        .UTSHero-imgs {
            background-size: 110%;
        }
    }
    @media (max-width: 450px) {
        .UTSHero-imgs { height: 240px; }
    }
`

const UTSSectionStyle = styled.div`
    width: 100%;
    .uts-section {
        width: 100%;
        padding: 120px 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-weight: bold;
            margin-bottom: 10px;
            text-align: center;
            span {
                color: var(--secondary-color);
            }
        }
        h2 {
            font-weight: bold;
            span {
                color: var(--secondary-color);
            }
        }
        .uts-row-between {
            width: 100%;
            max-width: 1120px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 60px;
            .uts-graphic {
                img {
                    width: 100%;
                }
            }
            .uts-g1 {
                width: 740px;
            }
            .uts-g2 {
                width: 620px;
            }
            .uts-g4 {
                width: 700px;
            }
            .section-info {
                flex: 1;
                max-width: 450px;
                .section-title {
                    font-size: 32px;
                    font-weight: bold;
                    span {
                        color: var(--secondary-color);
                    }
                }
                .section-details {
                    margin-top: 10px;
                    .detail-item {
                        margin-top: 10px;
                    }
                }
            }
        }
        .uts-row-around {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            margin-top: 40px;
            .uts-function-card {
                width: 320px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .uts-card-graphic {
                    width: 100%;
                    max-width: 250px;
                    img {
                        width: 100%auto;
                    }
                }
            }
        }
        @media (max-width: 800px) {
            padding: 60px 20px 0;
            .uts-row-between {
                flex-direction: column;
                .uts-g1, .uts-g2, .uts-g4 {
                    width: 100%;
                    margin-bottom: 20px;
                }
                :last-child {
                    flex-direction: column-reverse;
                }
            }
            .uts-row-between.uts-single {
                flex-direction: column;
                margin-top: 0;
                .uts-graphic {
                    margin-top: 40px;
                }
            }
            .uts-row-around {
                flex-direction: column;
                align-items: center;
                .card-text {
                    margin-bottom: 40px;
                    h2 {
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .uts-gray-section {
        width: 100%;
        margin-top: 80px;
        padding: 80px 20px 0;
        background: var(--lightGray);
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-weight: bold;
            margin-bottom: 10px;
        }
        h2 {
            font-weight: bold;
            margin-bottom: 20px;
            span {
                color: var(--secondary-color);
            }
        }
        .uts-screens-row {
            width: 100%;
            max-width: 1020px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: -80px;
            .uts-screens {
                width: 600px;
                img {
                    width: 100%;
                }
            }

        }
        .uts-tech-node {
            h1 span {
                color: var(--secondary-color);
            }
            .tech-node {
                width: 100%;
                padding-left: 52px;
                margin-top: 10px;
                h2 {
                    margin-bottom: 5px;
                }
                h3 {
                    color: var(--dark);
                }
            }
            .tech-node.html5 {
                background: url("https://mtimg.onemiao.cn/web-images/uts/uts_icon_html5.svg") left center no-repeat;
                background-size: 32px;
            }
            .tech-node.mini-app {
                background: url("https://mtimg.onemiao.cn/web-images/uts/uts_icon_wechat.svg") left center no-repeat;
                background-size: 32px;
            }
            .tech-node.sdk {
                background: url("https://mtimg.onemiao.cn/web-images/uts/uts_icon_api.svg") left center no-repeat;
                background-size: 32px;
            }
        }
        @media (max-width: 800px) {
            .uts-screens-row {
                flex-direction: column;
                margin-bottom: 40px;
                .uts-screens {
                    margin-bottom: 40px;
                    width: 100%;
                }
            }
        }
    }
`

export default function MiaotongUTS() {
    return <div>
        <UTSHeroStyle>
            <div className="UTSHero-text">
                <div className="title">苗通<span>UTS</span></div>
                <h2>苗通用户推广渠道源系统</h2>
                <h3>Miao User Traffic Source</h3>
                <h2>对线上疫苗消费者的需求进行全域链接</h2>
            </div>
            <div className="UTSHero-imgs">
                <div className="left-img">
                    <img src="https://mtimg.onemiao.cn/web-images/uts/uts_hero_left.png" alt="" />
                </div>
                <div className="right-img">
                    <img src="https://mtimg.onemiao.cn/web-images/uts/uts_hero_right.png" alt="" />
                </div>
                <div className="center">
                    <img src="https://mtimg.onemiao.cn/web-images/uts/uts_hero_mid.png" alt="" />
                </div>
            </div>
        </UTSHeroStyle>
        <UTSSectionStyle>
            <div className="content-wrap">
                <div className="uts-section">
                    <h1>我是推广商</h1>
                    <h2>使用苗通<span>UTS</span>，激发疫苗推广<span>新活力</span></h2>
                    <div className="uts-row-between">
                        <div className="uts-graphic uts-g1">
                            <img src="https://mtimg.onemiao.cn/web-images/uts/uts_graphic1.png" alt="" />
                        </div>
                        <div className="section-info">
                            <div className="section-title">连接本地<span>流量主</span><br />带动潜在消费者</div>
                            <div className="section-details">
                                <div className="detail-item">
                                    <h2>有效触达</h2>
                                    <h3>覆盖本地80%流量主，多渠道触达本地用户</h3>
                                </div>
                                <div className="detail-item">
                                    <h2>精准人群</h2>
                                    <h3>多维度数据统计，识别潜在消费用户</h3>
                                </div>
                                <div className="detail-item">
                                    <h2>效果增长</h2>
                                    <h3>提供不同的推广方案，助力转化增长</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uts-row-between">
                        <div className="section-info">
                            <div className="section-title">无缝<span>链接</span>消费者<br />提升效率与体验</div>
                            <div className="section-details">
                                <div className="detail-item">
                                    <h2>全程信息化</h2>
                                    <h3>基于安通云数据中心，实现咨询、预约、报道、接种全流程服务信息化。</h3>
                                </div>
                                <div className="detail-item">
                                    <h2>一站式预约</h2>
                                    <h3>用户可能过苗通UTS咨询了解产品，同时可直接自主预约。</h3>
                                </div>
                                <div className="detail-item">
                                    <h2>专业化服务</h2>
                                    <h3>专业的服务团队，为消费者解答全流程线上线下所有疑问。</h3>
                                </div>
                            </div>
                        </div>
                        <div className="uts-graphic uts-g2">
                            <img src="https://mtimg.onemiao.cn/web-images/uts/uts_graphic2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </UTSSectionStyle>
        <UTSSectionStyle>
            <div className="content-wrap">
                <div className="uts-section">
                    <h1>三个“<span>超能力</span>”<br />助力订单精准交付</h1>
                    <div className="uts-row-around">
                        <div className="uts-function-card">
                            <div className="uts-card-graphic">
                                <img src="https://mtimg.onemiao.cn/web-images/uts/uts_f1_g1.svg" alt="" />
                            </div>
                            <div className="card-text">
                                <h2>超安全</h2>
                                <h3>全站HTTPS加密传输储存，<br />阿里云安全保障认证</h3>
                            </div>
                        </div>
                        <div className="uts-function-card">
                            <div className="uts-card-graphic"><img src="https://mtimg.onemiao.cn/web-images/uts/uts_f1_g2.svg" alt="" /></div>
                            <div className="card-text">
                                <h2>超精准</h2>
                                <h3>订单成交及交付实施，<br />系统智能匹配</h3>
                            </div>
                        </div>
                        <div className="uts-function-card">
                            <div className="uts-card-graphic"><img src="https://mtimg.onemiao.cn/web-images/uts/uts_f1_g3.svg" alt="" /></div>
                            <div className="card-text">
                                <h2>超清晰</h2>
                                <h3>预约数据自动统计生成报表，<br />随时查询</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UTSSectionStyle>
        <UTSSectionStyle>
            <div className="uts-gray-section">
                <h1>我是流量主</h1>
                <h2>伴随流量留存与增长，共创<span>流量变现</span>新价值</h2>
                <div className="uts-screens-row">
                    <div className="uts-screens"><img src="https://mtimg.onemiao.cn/web-images/uts/uts_graphic3.png" alt="" /></div>
                    <div className="uts-tech-node">
                        <h1>接入场景<span>全覆盖</span>，<br />灵活开启合作</h1>
                        <div className="tech-node html5">
                            <h2>微信H5页面</h2>
                            <h3>轻量级，即开即用易于传播</h3>
                        </div>
                        <div className="tech-node mini-app">
                            <h2>微信小程序</h2>
                            <h3>定制开发，拥有自己的专属平台</h3>
                        </div>
                        <div className="tech-node sdk">
                            <h2>API/SDK开发</h2>
                            <h3>打通自有APP，实现个性化自主开发</h3>
                        </div>
                    </div>
                </div>
            </div>
        </UTSSectionStyle>
        <UTSSectionStyle>
            <div className="content-wrap">
                <div className="uts-section">
                    <div className="uts-row-between uts-single">
                        <div className="section-info">
                            <div className="section-title">强大的管理后台，<br />掌握<span>实时动态</span></div>
                            <div className="section-details">
                                <div className="detail-item">
                                    <h2>运营成果分析</h2>
                                    <h3>订单全程信息化跟踪，<br />精准分析运营与转化成果</h3>
                                </div>
                                <div className="detail-item">
                                    <h2>数据实时更新</h2>
                                    <h3>订单信息实时同步，随时掌握业务动态</h3>
                                </div>
                                <div className="detail-item">
                                    <h2>精准识别用户</h2>
                                    <h3>多维度绘制用户画像，<br />助力精准识别目标用户特征</h3>
                                </div>
                            </div>
                        </div>
                        <div className="uts-graphic uts-g4">
                            <img src="https://mtimg.onemiao.cn/web-images/uts/uts_graphic4.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </UTSSectionStyle>
        <UTSSectionStyle>
            <div className="content-wrap">
                <div className="uts-section">
                    <h1>选择苗通<span>UTS</span>，让运营变现<span></span>更省心</h1>
                    <div className="uts-row-around">
                        <div className="uts-function-card">
                            <div className="uts-card-graphic"><img src="https://mtimg.onemiao.cn/web-images/uts/uts_f2_g1.svg" alt="" /></div>
                            <div className="card-text">
                                <h2>可靠的公益属性平台</h2>
                                <h3>背靠公立医院官方服务体系，<br />拒绝私立医院乱加价。<br />全品类疫苗享受国家标准定价。</h3>
                            </div>
                        </div>
                        <div className="uts-function-card">
                            <div className="uts-card-graphic"><img src="https://mtimg.onemiao.cn/web-images/uts/uts_f2_g2.svg" alt="" /></div>
                            <div className="card-text">
                                <h2>专业的运营服务支持</h2>
                                <h3>提供专业的增长与转化方案支持，<br />助力高效实现用户运营目标。</h3>
                            </div>
                        </div>
                        <div className="uts-function-card">
                            <div className="uts-card-graphic"><img src="https://mtimg.onemiao.cn/web-images/uts/uts_f2_g3.svg" alt="" /></div>
                            <div className="card-text">
                                <h2>高效的技术支持团队</h2>
                                <h3>高效敏捷的技术团队，及时解决问题，保障平台稳定运行。</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UTSSectionStyle>
        <Contact product={'UTS'} />
    </div>
}